@font-face {
    font-family: 'mokoto_glitch_mark_iiregular';
    src: url('../src/assets/fonts/glitch_mark_1-webfont.woff2') format('woff2'),
         url('../src/assets/fonts/glitch_mark_1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'mokoto_glitch_outlineregular';
    src: url('../src/assets/fonts/glitch_mark_outline-webfont.woff2') format('woff2'),
         url('../src/assets/fonts/glitch_mark_outline-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'mokoto_glitchregular';
    src: url('../src/assets/fonts/glitch-webfont.woff2') format('woff2'),
         url('../src/assets/fonts/glitch-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'mokoto_glitch_mark_iregular';
    src: url('../src/assets/fonts/glitch_mark_1-webfont.woff2') format('woff2'),
         url('../src/assets/fonts/glitch_mark_1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

  @font-face {
    font-family: 'Avenir LT Std';
    src: url('../src/assets/fonts/AvenirLTStd-Book.eot');
    src: url('../src/assets/fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
         url('../src/assets/fonts/AvenirLTStd-Book.woff2') format('woff2'),
         url('../src/assets/fonts/AvenirLTStd-Book.woff') format('woff'),
         url('../src/assets/fonts/AvenirLTStd-Book.svg#Avenir LT Std') format('svg');
}



/* remove arrows in number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}